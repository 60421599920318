import React, { useEffect, useState } from 'react';
import { Center, Text, Tabs, TabList, Tab } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { HiMicrophone, HiMapPin, HiBookmark } from 'react-icons/hi2';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

function TabNavigation() {
  const { accessCode } = useParams();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(-1);

  useEffect(() => {
    if (location.pathname.includes('saved-sessions')) setTabIndex(2);
    else if (location.pathname.includes('locations')) setTabIndex(1);
    else setTabIndex(0);
  }, [location]);

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  return (
    <Tabs isFitted index={tabIndex} onChange={handleTabsChange}>
      <TabList mb="2">
        <Tab as={Link} to={`/${accessCode}`}>
          <Center>
            <Icon mr={2} as={HiMicrophone} />
            <Text>Programme</Text>
          </Center>
        </Tab>
        <Tab as={Link} to={`/${accessCode}/locations`}>
          <Center>
            <Icon mr={2} as={HiMapPin} />
            <Text>Theatres</Text>
          </Center>
        </Tab>
        <Tab as={Link} to={`/${accessCode}/saved-sessions`}>
          <Center>
            <Icon mr={2} as={HiBookmark} />
            <Text align="center">My Schedule</Text>
          </Center>
        </Tab>
      </TabList>
    </Tabs>
  );
}

export default TabNavigation;
